<template>
  <div class="p-1">
    <div v-if="showError" class="w-100 text-center pt-4">
      <h2 class="mb-1">Page Not Found 🕵🏻‍♀️</h2>
      <p class="mb-2">
        Oops! 😖 The requested URL was not found on this server.
      </p>
      <b-img fluid :src="imgUrl" alt="Error page" />
    </div>
    <b-row v-else class="match-height" align-h="center">
      <b-col md="7">
        <!-- <b-card v-if="showMessage">
          <b-row align-h="center">
            <b-col class="text-center">
              <b-img
                ref="previewEl"
                src="https://cdn.cloudious.net/file-1677912450367-333289208.png"
                style="border-radius: 100px"
                fluid
                height="100px"
                width="100px"
              />
              <div class="mt-1">
                <h5>School Name</h5>
                <h5>School Email</h5>
                <h5>School Contact</h5>
              </div>
            </b-col>
          </b-row>

          <b-row align-h="center" class="mt-1">
            <b-col class="text-center">
              <feather-icon
                icon="CheckCircleIcon"
                size="65"
                class="text-success mb-1"
              />
              <h4>Application Submiited</h4>
              <h4>Thank You for Registration!</h4>
              <h4>We will review your request</h4>
            </b-col>
          </b-row>
        </b-card> -->
        <b-row align-h="center" class="mb-1">
          <b-col class="text-center" md="12">
            <b-img
              v-if="school.logo == null || school.logo == ''"
              ref="previewEl"
              src="https://cdn.cloudious.net/file-1678110140675-811622963.png"
              style="object-fit: contain"
              fluid
              height="100px"
              width="100px"
            />
            <b-img
              v-else
              ref="previewEl"
              :src="school.logo"
              style="object-fit: contain"
              fluid
              height="100px"
              width="100px"
            />
            <div class="mt-1">
              <h3>{{ school.name }}</h3>
              <h5>
                <feather-icon icon="MapPinIcon" class="mr-50" />
                {{ school.address }}
              </h5>
              <h5>
                <feather-icon icon="PhoneIcon" class="mr-50" />
                {{ school.phone || school.mobile }}
              </h5>
            </div>
          </b-col>
        </b-row>
        <b-card class="">
          <h4 class="text-uppercase mb-0">Staff Registration</h4>
          <hr />
          <!-- <b-row align-h="center">
            <b-col class="text-center">
              <b-img
                ref="previewEl"
                src="https://cdn.cloudious.net/file-1677912450367-333289208.png"
                style="border-radius: 100px"
                fluid
                height="100px"
                width="100px"
              />
              <div class="mt-1">
                <h3>Lorem ipsum</h3>
                <h5>
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                  Mollitia necessitatibutur iste aperia suscipit.
                </h5>
                <h5>0322 6482902</h5>
              </div>
            </b-col>
          </b-row>
          <hr /> -->
          <b-row align-h="center">
            <!-- <b-col md="12">
              <h4 class="text-center mt-1">Application Form</h4>
            </b-col> -->
            <b-col class="ml-3" cols="3">
              <b-form-group
                class="text-center"
                ref="picture"
                :state="nameState"
                label=" "
                label-for="name-input"
                invalid-feedback="Picture is required."
              >
                <b-media no-body ref="picture">
                  <div v-if="logoloading == true">
                    <b-spinner
                      variant="primary"
                      type="grow"
                      class="m-5"
                      label="Spinning"
                    ></b-spinner>
                  </div>

                  <b-media-aside>
                    <b-link>
                      <b-img
                        ref="previewEl"
                        src="https://cdn.cloudious.net/file-1677912450367-333289208.png"
                        @click="$refs.fileProfile.click()"
                        v-if="logoloading == false"
                        style="border-radius: 100px"
                        fluid
                        height="120px"
                        width="120px"
                      />
                    </b-link>

                    <b-link v-if="logoloading == 'loaded'">
                      <div class="text-right" style="margin-right: 35px">
                        <b-button
                          @click="deleteLogo()"
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          class="position-absolute btn-icon"
                          variant="danger"
                        >
                          <feather-icon icon="Trash2Icon" />
                        </b-button>
                      </div>

                      <b-img
                        class="rcorners2"
                        :src="plus"
                        height="120px"
                        width="120px"
                      />

                      <br />
                    </b-link>
                    <input
                      type="file"
                      id="fileProfile"
                      hidden
                      ref="fileProfile"
                      @change="selectlogo()"
                      accept="image/*"
                    />
                  </b-media-aside>
                </b-media>
                <small class="mr-1 text-danger" v-if="logoerror"
                  >Logo is required.</small
                >
              </b-form-group>
            </b-col>
            <!-- <b-col cols="4"> </b-col> -->
          </b-row>
          <!-- <h3 class="mt-1">Student Information</h3> -->

          <b-row>
            <b-col md="6">
              <b-form-group
                label="Name *"
                invalid-feedback="Name is required."
                label-class="font-weight-bold"
              >
                <b-form-input
                  placeholder="Enter name."
                  ref="name"
                  v-model.trim="myObj.staff.name"
                  @focusout="CheckName()"
                />
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                label="Father name *"
                invalid-feedback="Father name is required."
                label-class="font-weight-bold"
              >
                <b-form-input
                  ref="fname"
                  placeholder="Enter father name."
                  v-model.trim="myObj.staff.father_name"
                  @focusout="CheckFName()"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Gender *"
                invalid-feedback="School name is required."
                label-class="font-weight-bold"
                ref="gender"
              >
                <v-select
                  v-model="myObj.staff.gender"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="genders"
                  :clearable="false"
                  placeholder="Select gender"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Date of Birth *"
                invalid-feedback="Date of Birth is required."
                label-class="font-weight-bold"
                ref="dob"
              >
                <flat-pickr
                  :config="config"
                  v-model="myObj.staff.date_of_birth"
                  class="form-control"
                  placeholder="Select date of birth."
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Contact *"
                invalid-feedback="Contact number is required."
                label-class="font-weight-bold"
              >
                <vue-tel-input
                  v-model="myObj.staff.contact_number"
                  :inputOptions="vueTelOptions"
                  :dropdownOptions="dropOptions"
                  @input="CheckSContact"
                ></vue-tel-input>
                <small class="mr-1 text-danger" v-if="sPhoneerror"
                  >Contact number is required</small
                >
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Qualification *"
                invalid-feedback="qualification is required."
                label-class="font-weight-bold"
              >
                <b-form-input
                  ref="qua"
                  placeholder="Enter qualification."
                  v-model.trim="myObj.staff.qualification"
                />
                <!-- @focusout="CheckQualification()" -->
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Designation *"
                invalid-feedback="designation is required."
                label-class="font-weight-bold"
              >
                <b-form-input
                  ref="design"
                  placeholder="Enter designation."
                  v-model.trim="myObj.staff.designation"
                />
                <!-- @focusout="CheckDesignation()" -->
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Experience"
                invalid-feedback="Experience is required."
              >
                <b-form-input
                  placeholder="Enter experience."
                  v-model.trim="myObj.staff.experience"
                />
              </b-form-group>
            </b-col>
            <b-col md="6" v-if="db == '/r6Q0RYpjkcmj801xyc19w=='">
              <b-form-group
                label="Campus *"
                invalid-feedback="Campus is required."
                label-class="font-weight-bold"
                ref="camp"
              >
                <v-select
                  v-model="myObj.staff.campusID"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="campuses"
                  :clearable="false"
                  :reduce="(val) => val.id"
                  label="branch"
                  placeholder="Select campus"
                  @input="setData()"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Department *"
                invalid-feedback="Department is required."
                label-class="font-weight-bold"
                ref="dep"
              >
                <v-select
                  v-model="myObj.staff.departmentID"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="departments"
                  :clearable="false"
                  :reduce="(val) => val.id"
                  label="name"
                  :disabled="depLoading"
                  placeholder="Select department"
                  @input="CheckDep()"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group
                label="Teaching Level"
                invalid-feedback="Teaching Level is required."
              >
                <b-form-input
                  placeholder="Enter teaching level"
                  v-model.trim="myObj.staff.teachingLevel"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="CNIC *"
                invalid-feedback="CNIC is required."
                label-class="font-weight-bold"
                ref="cnic"
              >
                <b-form-input
                  placeholder="Enter CNIC"
                  v-model.trim="myObj.staff.cnic"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Applied on"
                invalid-feedback="Applied on is required."
              >
                <flat-pickr
                  :config="config"
                  v-model="myObj.staff.registrationDate"
                  class="form-control"
                  placeholder="Select date"
                  disabled
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Current Address *"
                invalid-feedback="Contact number is required."
                label-class="font-weight-bold"
                ref="address"
              >
                <b-form-input
                  placeholder="Enter current address."
                  v-model.trim="myObj.staff.address_current"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Permanent Address"
                invalid-feedback="Contact number is required."
              >
                <b-form-input
                  placeholder="Enter permanent address."
                  v-model.trim="myObj.staff.address_permanent"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <b-form-group
                label="Teaching Subjects"
                invalid-feedback="Subjects are required."
                ref="sub"
              >
                <v-select
                  v-model="myObj.subjectIDs"
                  multiple
                  :closeOnSelect="false"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="subjects"
                  :reduce="(opt) => opt.id"
                  label="subject"
                  :clearable="false"
                  :disabled="dataloading"
                  placeholder="Select teaching subjects"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Availability"
                invalid-feedback="Availability is required."
              >
                <v-select
                  v-model="myObj.staff.availibility"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="availibility"
                  :clearable="false"
                  input-id="categoryId"
                  ref="avail"
                  placeholder="Your availability"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                label="Expected Salary *"
                invalid-feedback="Expected salary is required."
                label-class="font-weight-bold"
              >
                <b-form-input
                  ref="salary"
                  type="number"
                  v-model="myObj.staff.expectedSalary"
                  class="form-control"
                  placeholder="Enter expected salary"
                />
              </b-form-group>
            </b-col>
            <b-col md="12" class="mt-2">
              <div class="file-uploader">
                <h5>
                  <b-spinner
                    variant="dark"
                    label="Spinning"
                    v-if="uploading"
                  ></b-spinner>
                  <span v-else> Upload Documents </span>
                </h5>
                <input
                  ref="cvDoc"
                  type="file"
                  class="file_upload"
                  @change="uploadDocument()"
                  multiple="true"
                />
              </div>
            </b-col>
            <b-col md="12" class="mt-1">
              <template v-for="(file, index) in documents">
                <div class="file">
                  <a
                    :href="file"
                    target="_blank"
                    style="display: flex; align-items: center"
                  >
                    <div class="file-type">
                      {{ file.split(".").pop().toUpperCase() }}
                    </div>
                    <p class="file-name">{{ file }}</p>
                  </a>
                  <div class="file-delete">
                    <i
                      class="fa fa-trash"
                      aria-hidden="true"
                      @click="deleteDocument(index)"
                    ></i>
                  </div>
                </div>
              </template>
            </b-col>
          </b-row>
        </b-card>
        <b-button
          @click="formSubmitted()"
          variant="success"
          :disabled="request || uploading"
          block
        >
          <b-spinner v-if="request" type="grow" small label="Loading..." />
          <span v-else>Submit</span>
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import Ripple from "vue-ripple-directive";
import { mapActions, mapGetters } from "vuex";
import vue2Dropzone from "vue2-dropzone";

import "vue2-dropzone/dist/vue2Dropzone.min.css";

import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BCard,
  BButton,
  BMediaAside,
  BImg,
  BMedia,
  BLink,
  BSpinner,
  BBadge,
  BFormFile,
  BFormTextarea,
  BTable,
} from "bootstrap-vue";
import { resolve } from "path";
import { reject } from "q";
export default {
  components: {
    vueDropzone: vue2Dropzone,
    // DropZone,
    BFormTextarea,
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    flatPickr,
    BCard,
    BButton,
    BMediaAside,
    BImg,
    BMedia,
    BLink,
    BSpinner,
    BBadge,
    BFormFile,
    BTable,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      file: [],
      documents: [],
      uploading: false,
      request: false,
      plus: "",
      logoloading: false,
      netAmount: 0,
      dataloading: false,
      admittedSections: [],
      currentSections: [],
      classes: [],
      genders: ["Male", "Female"],
      availibility: ["Part Time", "Full Time"],
      groups: ["A", "B", "O", "AB"],
      religions: ["Islam", "Christianity", "Hinduism", "Buddhists"],
      config: {
        altFormat: "j M, Y",
        altInput: true,
        dateFormat: "Y-m-d",
      },
      myObj: {
        staff: {
          id: 0,
          rfID: "",
          name: "",
          father_name: "",
          designation: "",
          gender: "",
          picture: "",
          date_of_birth: null,
          address_current: "",
          address_permanent: "",
          contact_number: "",
          emergency_contact_person: "",
          emergency_contact_relation: "",
          emergency_number: "",
          qualification: "",
          experience: "",
          campusID: this.$store.state.userData.cId,
          status: "applicant",
          documents: "",
          availibility: "",
          expectedSalary: 0,
          departmentID: 0,
          cnic: "",
          teachingLevel: "",
          applicantStatus: "pending",
          registrationDate: new Date().toJSON(),
          appointedOn: null,
          leaves_allowed_from: null,
          basicSalary: 0,
          totalSalary: 0,
        },
        subjectIDs: [],
      },
      classObj: {
        class_admitted: null,
        section_admitted: null,
        subject_admitted: null,
      },
      fields: [
        { label: "Class", key: "class_admitted" },
        { label: "section", key: "section_admitted" },
        { label: "subject", key: "subject_admitted" },
      ],
      // for newTeachingList
      // fields2: [
      //   { label: "Class", key: "className" },
      //   { label: "section", key: "sectionName" },
      //   { label: "subject", key: "subjects" },
      //   "actions",
      // ],
      fields2: [
        { label: "Class", key: "cls" },
        { label: "section", key: "sec" },
        { label: "subject", key: "subjects" },
        "actions",
      ],

      classesOptions: [],
      teachingList: [],
      teachingClasses: [],
      newTeachingList: [],
      gridData: [],
      // teachingClassObj: {
      //   cID: 0,
      //   sectionName: "",
      //   subjects: 0,
      // },
      teachingClassObj: {
        tID: this.$route.params.id,
        clsID: 0,
        sections: [],
        subjects: [],
        campusID: this.$store.state.userData.cId,
      },
      showMessage: false,
      checkvalue: {},
      fphoneerror: false,
      mphoneerror: false,
      phoneerror: false,
      emerPhoneerror: false,
      sPhoneerror: false,
      fileProfile: "",
      vueTelOptions: { placeholder: "Enter contact number" },
      dropOptions: {
        disabled: false,
        showDialCodeInList: true,
        showFlags: true,
      },
      emerOpt: { placeholder: "Enter emergency contact" },
      subjects: [],
      docLoading: false,
      mediaLoaded: false,
      campuses: [],
      departments: [],
      depLoading: false,
      school: {},
      token:
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkZW1vQG15c2tvb2wuYXBwIiwianRpIjoiZmRjZjdhYzQtNmY1OS00Nzg0LTkyNDItZjFjNjRhOTdjODQ0IiwiZXhwIjoxOTkzNzk2MDgwLCJpc3MiOiJteXNrb29sLmFwcCIsImF1ZCI6Im15c2tvb2wuYXBwIn0._pMXprq3AJApizQQlcrVXS3-dPyGFMi0nT9OfLQKBlo",
      db: this.$route.query.db,
      cId: this.$route.query.cId,
      showError: false,
    };
  },
  //   mounted() {
  //     this.$refs.wizard.activateAll();
  //   },
  created() {
    // console.log(this.db, this.cId);
    if (!this.$route.query.db || !this.$route.query.cId) {
      this.showError = true;
    } else {
      this.LoadDep();
      this.LoadInfo(this.$route.query.cId);
      this.LoadSchool();
      if (this.db == "/r6Q0RYpjkcmj801xyc19w==") {
        this.LoadCampuses();
      }
      this.myObj.staff.campusID = parseInt(this.$route.query.cId);
      setTimeout(() => {
        this.sPhoneerror = false;
      }, 300);
    }
  },
  computed: {
    ...mapGetters({ campusID: "campusID" }),

    imgUrl() {
      let img = require("@/assets/images/pages/error.svg");
      if (this.$store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        img = require("@/assets/images/pages/error-dark.svg");
        return img;
      }
      return img;
    },
  },
  methods: {
    ...mapActions({ getDepartment: "getDepartment" }),
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),

    setData() {
      this.myObj.subjectIDs = [];
      this.LoadInfo(this.myObj.staff.campusID);
      let obj = this.campuses.find((el) => el.id == this.myObj.staff.campusID);
      if (obj) {
        this.school.address = obj.address;
        this.school.phone = obj.phone;
        this.school.mobile = obj.mobile;
      }
    },
    deleteDocument(index) {
      this.documents.splice(index, 1);
      this.myObj.staff.documents = this.documents.join(",");
    },
    uploadDocument() {
      let docs = this.$refs.cvDoc.files;
      if (docs.length != 0) {
        this.uploading = true;
        let formData = new FormData();
        docs.forEach((el) => {
          formData.append("file", el);
        });
        var axios = require("axios");
        axios
          .post("https://upload.myskool.app", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((e) => {
            // console.log(e.data);
            let resp = e.data.myresp;
            resp.forEach((el) => {
              this.documents.push(el.path);
            });
            this.myObj.staff.documents = this.documents.join(",");
            this.uploading = false;
            // console.log(this.myObj.staff.documents);
          });
      }
    },
    deleteLogo() {
      //console.log(0);
      this.myObj.staff.picture = null;
      this.logoloading = false;
      this.$refs.fileProfile.value = null;
    },
    selectlogo() {
      // console.log(this.$refs.fileProfile.files[0]);
      this.fileProfile = this.$refs.fileProfile.files[0];
      // console.log(this.fileProfile);
      if (this.fileProfile !== "") {
        this.logoloading = true;
        let formData = new FormData();
        var axios = require("axios");
        formData.append("file", this.fileProfile);
        axios
          .post("https://upload.myskool.app/profile-picture", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((e) => {
            // console.log(e.data);
            // var fn1 = e.data.myresp[0].path;
            var fn1 = e.data.file.path;
            // console.log(fn1);
            this.plus = fn1;
            this.myObj.staff.picture = this.plus;
            // console.log(this.plus);
            //console.log(this.myObj.coverImg);
            this.logoloading = "loaded";
          })
          .catch((err) => {
            this.logoloading = false;
            console.log(err);
          });
      }
    },
    removeCV() {
      this.myObj.cv = null;
      this.mediaLoaded = false;
      this.$refs.cvDoc.files = null;
    },
    addCV() {
      let doc = this.$refs.cvDoc.files[0];
      if (doc != "") {
        this.mediaLoaded = false;
        this.docLoading = true;
        let formData = new FormData();
        formData.append("file", doc);
        var axios = require("axios");
        axios
          .post("https://upload.myskool.app", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((e) => {
            console.log(e.data);
            let resp = e.data.myresp[0].path;
            this.myObj.cv = resp;
            this.docLoading = false;
            this.mediaLoaded = true;
            console.log(this.myObj.cv);
          });
      }
    },
    CheckName() {
      var elem = this.$refs["name"];
      if (this.myObj.staff.name == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckFName() {
      var elem = this.$refs["fname"];
      if (this.myObj.staff.father_name == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckQualification() {
      var elem = this.$refs["qua"];
      if (this.myObj.staff.qualification == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckDesignation() {
      var elem = this.$refs["design"];
      if (this.myObj.staff.designation == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckFContact() {
      // console.log(this.myObj.contact_father);
      // console.log(this.myObj.contact_father.length);
      if (this.myObj.contact_father.length == 12) {
        this.fphoneerror = false;
        return true;
      } else {
        this.fphoneerror = true;
        return false;
      }
    },
    CheckMContact() {
      // console.log(this.myObj.contact_mother);
      // console.log(this.myObj.contact_mother.length);
      if (this.myObj.contact_mother.length == 12) {
        this.mphoneerror = false;
        return true;
      } else {
        this.mphoneerror = true;
        return false;
      }
    },
    CheckSContact(s, e) {
      // console.log(this.myObj.contact_number);
      // console.log(this.myObj.contact_student.length);
      // if (this.myObj.staff.contact_number.length == 12) {
      //   this.sPhoneerror = false;
      //   return true;
      // } else {
      //   this.sPhoneerror = true;
      //   return false;
      // }
      if (e.valid) {
        this.sPhoneerror = false;
        this.myObj.staff.contact_number = e.number;
      } else {
        this.sPhoneerror = true;
      }
    },

    CheckEmerName() {
      var elem = this.$refs["emerName"];
      if (
        this.myObj.staff.emergency_name == null ||
        this.myObj.staff.emergency_name == ""
      ) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckEmerRelation() {
      var elem = this.$refs["emerRel"];
      if (
        this.myObj.staff.emergency_relationship == null ||
        this.myObj.staff.emergency_relationship == ""
      ) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckEmerContact() {
      if (this.myObj.staff.emergency_contact.length == 12) {
        // console.log(this.myObj.staff.emergency_contact.length);
        this.emerPhoneerror = false;
        return true;
      } else {
        this.emerPhoneerror = true;
        return false;
      }
    },
    CheckSalary() {
      const regex = /^(\d*([.,](?=\d{3}))?\d+)+((?!\2)[.,]\d\d)?$/;
      var elem = this.$refs["salary"];
      if (regex.test(this.myObj.staff.expectedSalary)) {
        if (this.myObj.staff.expectedSalary == 0) {
          return (elem.state = false);
        } else {
          this.myObj.staff.expectedSalary = parseInt(
            this.myObj.staff.expectedSalary
          );
          return (elem.state = true);
        }
      } else {
        return (elem.state = false);
      }
    },
    CheckGrNo() {
      var elem = this.$refs["grNo"];
      if (this.myObj.grNo == null || this.myObj.grNo == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckDep() {
      var elem = this.$refs["dep"];
      if (this.myObj.staff.departmentID == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckCnic() {
      var elem = this.$refs["cnic"];
      if (!this.myObj.staff.cnic) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckDate() {
      var elem = this.$refs["dob"];
      if (!this.myObj.staff.date_of_birth) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckGender() {
      var elem = this.$refs["gender"];
      if (this.myObj.staff.gender == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckAddress() {
      var elem = this.$refs["address"];
      if (this.myObj.staff.address_current == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckTcID() {
      var elem = this.$refs["tcID"];
      if (this.teachingClassObj.clsID == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckTSection() {
      // console.log(this.teachingClassObj.sections.length);
      var elem = this.$refs["tSection"];
      if (this.teachingClassObj.sections.length === 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckTSubject() {
      // console.log(this.teachingClassObj.subjects.length);
      var elem = this.$refs["sub"];
      if (this.myObj.subjectIDs.length === 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    FillSections() {
      console.log(this.teachingClassObj.clsID);
      // this.teachingClassObj.sections = [];
      this.classes.forEach((elem) => {
        if (elem.cls.id === this.teachingClassObj.clsID) {
          if (elem.sections.length != 0) {
            console.log("not null");
            this.admittedSections = elem.sections;
          } else {
            console.log("null");
            this.admittedSections = [];
          }
        }
      });
      console.log("Thisis", this.admittedSections);
    },
    async assign() {
      this.teachingClassObj.tID = parseInt(this.$route.params.id);
      console.log(this.teachingClassObj);
      this.CheckTcID();
      this.CheckTSubject();
      this.CheckTSection();
      if (
        this.CheckTcID() == false ||
        this.CheckTSubject() == false ||
        this.CheckTSection() == false
      ) {
        return this.$bvToast.toast("Please enter all the required fields.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-right",
        });
      } else {
        var status = await this.post({
          url:
            this.$store.state.domain +
            "TeacherClasses/SaveData?db=" +
            this.$store.state.userData.db,
          body: this.teachingClassObj,
          message: "Classes assigned successfully.",
          context: this,
          token: this.token,
        });
        if (status) {
          this.LoadTeachingClasses();
          this.teachingClassObj = {
            tID: this.$route.params.id,
            clsID: 0,
            sections: [],
            subjects: [],
            campusID: this.$store.state.userData.cId,
          };
        }
      }
    },
    FillSectionsCurrent() {
      //return console.log(this.classes);
      this.classes.forEach((elem) => {
        if (elem.id == this.myObj.class_current) {
          this.currentSections = elem.sections.split(",");
        }
      });
      console.log("Thisis", this.admittedSections);
    },
    async LoadData(id) {
      var obj = {
        url:
          this.$store.state.domain +
          "staff/LoadSelectedApplicant?db=" +
          this.$store.state.userData.db +
          "&id=" +
          id,
        token: this.token,
      };
      this.myObj = await this.get(obj);
      console.log(this.myObj);
      if (
        this.myObj.staff.picture !== null &&
        this.myObj.staff.picture !== ""
      ) {
        this.plus = this.myObj.staff.picture;
        this.logoloading = "loaded";
      }
      if (
        this.myObj.staff.documents !== null &&
        this.myObj.staff.documents !== ""
      ) {
        this.documents = this.myObj.staff.documents.split(",");
        // console.log(this.documents);
      }
    },
    async LoadInfo(id) {
      this.dataloading = true;

      var obj = {
        url:
          this.$store.state.domain +
          "Subjects?db=" +
          (this.db || this.$store.state.userData.db) +
          "&cId=" +
          id,
        token: this.token,
      };
      this.subjects = await this.get(obj);

      // console.log("subject", this.subjects);
      this.dataloading = false;
    },
    async LoadSchool() {
      var obj = {
        url:
          this.$store.state.domain +
          "Campuses/GetSelected?id=" +
          (this.cId || this.$store.state.userData.cId) +
          "&db=" +
          (this.db || this.$store.state.userData.db),
        token: this.token,
      };
      let res = await this.get(obj);
      if (res == "NotFound" || Array.isArray(res)) this.showError = true;
      else this.school = res;
      // console.log("sch:", this.school);
    },
    async LoadDep() {
      this.depLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "StaffDepartments?db=" +
          (this.db || this.$store.state.userData.db) +
          "&cId=" +
          (this.cId || this.$store.state.userData.cId),
        token: this.token,
      };
      this.departments = await this.get(obj);
      this.depLoading = false;
    },
    async LoadCampuses() {
      var obj = {
        url:
          this.$store.state.domain +
          "campuses?db=" +
          this.db +
          "&cId=" +
          this.cId,
        token: this.token,
      };
      this.campuses = await this.get(obj);
    },
    EditTeaching(item) {
      console.log(item);
      this.teachingClassObj = {
        tID: this.$route.params.id,
        clsID: 0,
        sections: [],
        subjects: [],
        campusID: this.$store.state.userData.cId,
      };
      this.teachingClassObj.clsID = item.clsID;
      item.subjects.forEach((el) => this.teachingClassObj.subjects.push(el.id));
      this.FillSections();
      this.teachingClassObj.sections = item.sections;
    },
    async DeleteTeaching(item) {
      console.log(item);
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Delete it!",
      });

      if (result.isConfirmed) {
        var status = await this.post({
          url:
            this.$store.state.domain +
            "TeacherClasses/Remove?db=" +
            this.$store.state.userData.db,
          body: item,
          message: "Teaching classes removed successfully.",
          context: this,
          token: this.token,
        });
        console.log(status);
        if (status) this.LoadTeachingClasses();
      }
    },
    cleanStates() {
      var elem = this.$refs["name"];
      elem.state = undefined;
      var elem = this.$refs["fname"];
      elem.state = undefined;
      var elem = this.$refs["design"];
      elem.state = undefined;
      var elem = this.$refs["qua"];
      elem.state = undefined;
      var elem = this.$refs["salary"];
      elem.state = undefined;
      var elem = this.$refs["dep"];
      elem.state = undefined;
      var elem = this.$refs["cnic"];
      elem.state = undefined;
      var elem = this.$refs["dob"];
      elem.state = undefined;
      var elem = this.$refs["gender"];
      elem.state = undefined;
      var elem = this.$refs["address"];
      elem.state = undefined;
    },
    async formSubmitted() {
      this.CheckName();
      this.CheckFName();
      // this.CheckSContact();
      this.CheckDesignation();
      this.CheckQualification();
      // this.CheckTSubject();
      this.CheckSalary();
      this.CheckDep();
      this.CheckCnic();
      this.CheckDate();
      this.CheckGender();
      this.CheckAddress();
      if (this.myObj.staff.contact_number == "") this.sPhoneerror = true;
      if (
        this.CheckName() == false ||
        this.CheckFName() == false ||
        this.myObj.staff.contact_number == "" ||
        this.sPhoneerror ||
        // this.CheckSContact() == false ||
        this.CheckDesignation() == false ||
        this.CheckQualification() == false ||
        // this.CheckTSubject() == false ||
        this.CheckSalary() == false ||
        this.CheckDep() == false ||
        this.CheckCnic() == false ||
        this.CheckDate() == false ||
        this.CheckGender() == false ||
        this.CheckAddress() == false
      ) {
        return this.$bvToast.toast("Please fill the form correctly!", {
          title: "Error!",
          variant: "danger",
          solid: true,
        });
      } else {
        if (this.myObj.staff.date_of_birth == "") {
          this.myObj.staff.date_of_birth = null;
        }
        // console.log("obj", this.myObj);
        this.request = true;
        var status = await this.post({
          url:
            this.$store.state.domain + "staff/SaveApplicantNew?db=" + this.db,
          body: {
            staff: this.myObj.staff,
            subjectIDs: this.myObj.subjectIDs,
            interviews: [],
            approvals: [],
          },
          registration: true,
          context: this,
          token: this.token,
        });
        this.request = false;
        if (status) {
          Swal.fire({
            title: "Thank you for submitting the form!",
            text: "We appreciate your interest and will get back to you as soon as possible.",
            icon: "success",
            // confirmButtonColor: "#3085d6",
            // confirmButtonText: "ok",
          });
          this.myObj = {
            staff: {
              id: 0,
              rfID: "",
              name: "",
              father_name: "",
              designation: "",
              gender: "",
              picture: "",
              date_of_birth: null,
              address_current: "",
              address_permanent: "",
              contact_number: "",
              emergency_contact_person: "",
              emergency_contact_relation: "",
              emergency_number: "",
              qualification: "",
              experience: "",
              campusID: this.myObj.staff.campusID,
              status: "applicant",
              documents: "",
              availibility: "",
              expectedSalary: 0,
              departmentID: 0,
              cnic: "",
              teachingLevel: "",
              applicantStatus: "pending",
              registrationDate: new Date().toJSON(),
              appointedOn: null,
              leaves_allowed_from: null,
              basicSalary: 0,
              totalSalary: 0,
            },
            subjectIDs: [],
          };
          this.cleanStates();
          setTimeout(() => {
            this.sPhoneerror = false;
          }, 300);
        }
      }
    },
  },
};
</script>
<style>
.heading {
  padding: 0;
  margin: 2.171303074670571vw 0;
  /* text-shadow: -4px 0 5px white, 0 4px 5px white, 4px 0 5px white,
          0 -4px 5px white; */
}
.input-form {
  margin: 0 70px;
  background: #fff;
}
#fileInput .custom-file-label {
  color: #707070;
  border: 1px dashed #33312f;
  text-align: center;
  padding: 2.5rem 1.5rem;
  width: 100%;
}
#fileInput input {
  display: none;
}
#imgInput input {
  display: none;
}
#imgInput {
  /* height: 8rem; */
  width: 50%;
}
#imgInput div {
  height: 8vw;
}
#imgInput .custom-file-label {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.type {
  background-color: red;
  border-radius: 10px;
  font-size: 0.8rem;
  padding: 10px;
  color: white;
}
.filename {
  padding-left: 25px;
}
.delete {
  margin: 0 0 0 auto;
  color: red;
}
#checkbox-1 {
  margin-right: 10px !important;
}
.submit {
  background-color: #00c192;
  color: #fff;
  width: 16.5vw;
  height: 4vw;
  font-weight: bold;
  border-color: #00c192;
}
.form-file-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 20px;
}
/* #prop-det, #prop-det div {
        height: 100%;
      } */

.item-group {
  width: 50%;
  /* margin-top: 2.269399707174231vw; */
}
.inputfields,
.inputfields:focus {
  border: 1px solid #33312f;
  border-radius: 0.5856515373352855vw;
  height: 2.562225475841874vw;
  outline: none !important;
  resize: none;
}
textarea.form-control {
  height: 8vw !important;
}
@media only screen and (max-width: 990px) {
  .mob-setting {
    padding: 0 1rem !important;
    width: 100vw !important;
    margin: 0 auto !important;
  }
  .mobile-view {
    margin: 0 !important;
  }
  /* .col-form-label,
  .form-file-text,
  .custom-control-label {
    font-size: 3vw;
  } */
  .item-group {
    margin-top: 0;
  }
  .margin-top-mobile {
    margin-top: 5vw;
  }
  /* .col-form-label {
    display: none;
  } */
  .form-input-mobile-text {
    width: 92vw;
    margin: 10px auto;
    border-radius: 2.8301610541727673vw;
    height: 12.781844802342606vw;
    font-size: 5.171303074670571vw;
    background-color: #f2f2f2 !important;
    border: 1px solid #33312f;
  }
  textarea.form-control {
    height: 12.781844802342606vw !important;
  }
  .form-input-mobile-text:focus {
    background-color: #00c192 !important;
    color: #fff !important;
  }
  .form-input-mobile-text::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: rgb(190, 190, 190);
    opacity: 1; /* Firefox */
    font-family: DINNextRoundedLTW01-Regular;
  }

  .form-input-mobile-text:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: rgb(190, 190, 190);
  }

  .form-input-mobile-text::-ms-input-placeholder {
    /* Microsoft Edge */
    color: rgb(190, 190, 190);
  }

  .form-input-mobile-text:focus::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #fff;
  }
  .form-file-input-mobile {
    border-radius: 2.8301610541727673vw;
    height: 12.781844802342606vw;
    background-color: #f2f2f2 !important;
    color: #fff !important;
  }
  .custom-file-label {
    border-radius: 2.8301610541727673vw !important;
    height: 12.781844802342606vw;
    background-color: #f2f2f2 !important;
    color: #000 !important;
    height: 40vw;
    border: 1px solid #33312f;
  }
  .submit {
    width: 50vw;
    height: 10vw;
  }
  .filename {
    font-size: 3vw;
  }
  .shadowHead {
    font-size: 8vw !important;
  }
  #imgInput div {
    height: 29vw !important;
  }
  .item-group {
    height: 30vw !important;
  }
}

@media only screen and (min-width: 1366px) {
  .submit {
    width: 15.5vw;
    height: 7.8vh;
  }
}
@media only screen and (min-width: 1440px) {
  .submit {
    width: 14.7vw;
    height: 6.7vh;
  }
}
@media only screen and (min-width: 1536px) {
  .submit {
    width: 13.8vw;
    height: 6.9vh;
  }
}
@media only screen and (min-width: 1920px) {
  .submit {
    width: 11vw;
    height: 5.5vh;
  }
}
#overlay {
  position: fixed; /* Sit on top of the page content */
  display: none; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}
</style>
<style scoped>
.file-uploader {
  position: relative;
  border: 1px solid #000;
  width: 100%;
  line-height: 30px;
  text-align: center;
  border-radius: 10px;
  font-family: "Helvetica", sans-serif;
  height: 50px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.file_upload {
  opacity: 0;

  /* IE 8 */
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";

  /* IE 5-7 */
  filter: alpha(opacity=0);

  /* Netscape or and older firefox browsers */
  -moz-opacity: 0;

  /* older Safari browsers */
  -khtml-opacity: 0;

  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.file {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
}

.file-type {
  background-color: #e62e2d;
  font-family: "Helvetica", sans-serif;
  color: white;
  border-radius: 10px;
  margin-right: 15px;
  height: 50px;
  width: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.file-name {
  font-family: "Helvetica", sans-serif;
  margin: 0px;
}

.file-delete i {
  color: #e62e2d;
  font-size: 2rem;
  cursor: pointer;
}
.rcorners2 {
  border-radius: 100px;
  border: 4px solid var(--primary);
  padding: 2px;
}
.sticky {
  position: fixed;
  bottom: 0;
  right: 0;
  margin-right: 60px;
  margin-bottom: 30px;
  /* position: fixed;
          bottom: 5%;
          z-index: 1031;  */
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
